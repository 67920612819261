import * as types from './mutation-types'

const mutations = {
  // 设置面包屑
  [types.SET_BREADCRUMB](state, list) {
    state.breadcrumb = list
  },

  // 设置用户信息
  [types.SET_USERINFO](state, userObj) {
    state.userInfo = userObj
  },

  // 设置是否登陆
  [types.SET_LOGIN](state, isLogin) {
    state.isLogin = isLogin
  }
}
export default mutations
