import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './index.css'
import Antd from 'ant-design-vue/es'
import 'ant-design-vue/dist/antd.css'
import application from './application'

Vue.use(Antd)
Vue.config.productionTip = false

// 初始化应用
window.application = application

// session数据存储到vuex保存登陆信息
const sessionUserInfo = sessionStorage.getItem('userInfo') || '{}'
const userInfo = JSON.parse(sessionUserInfo)
const isLogin = sessionUserInfo !== '{}'
store.dispatch('loginAction', {
  userInfo,
  isLogin
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
