import Vue from 'vue'
import VueRouter from 'vue-router'
import application from '../application'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login/index')
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/home/index')
  },
  // {
  //   path: '/list1',
  //   name: 'List',
  //   component: () => import('../views/list/list')
  // },
  // {
  //   path: '/list2-2-1',
  //   name: 'ListDetail',
  //   component: () => import('../views/list/listDetail')
  // },
  {
    path: '/404',
    name: 'Error',
    component: () => import('../views/layout/error')
  },
  // 订单明细
  {
    path: '/orderList',
    name: 'OrderList',
    component: () => import('../views/orderList/index')
  },
  // 注册用户明细
  {
    path: '/registerList',
    name: 'RegisterList',
    component: () => import('../views/registerList/index')
  },
  // 销售业绩统计
  {
    path: '/salesList',
    name: 'SalesList',
    component: () => import('../views/salesList/salelist')
  },
  // 销售业绩统计（新）
  {
    path: '/salesListNew',
    name: 'SalesListNew',
    component: () => import('../views/salesList/salelistNew')
  },
  // 费用明细
  {
    path: '/costList',
    name: 'CostList',
    component: () => import('../views/costList/index')
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (application.authorize.canAccess(to.path)) {
    if (to.path !== '/login' && !store.state.isLogin) {
      next('/login')
    } else {
      next()
    }
  } else {
    next('/404')
  }
})

export default router
