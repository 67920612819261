<template>
 <a-config-provider :locale="locale">
    <div id="app">
      <router-view v-if="routePath.search('login') > -1"></router-view>
      <layout v-else />
    </div>
  </a-config-provider>
</template>

<script>
import Layout from './views/layout'
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
import 'moment/locale/zh-cn'

export default {
  name: 'App',
  components: { Layout },
  data() {
    return {
      routePath: '',
      locale: zhCN
    }
  },
  watch: {
    $route(newV) {
      this.routePath = newV.path
    }
  }
}
</script>

<style lang="scss" scoped>
  #app {
    height: 100%;
  }
</style>
