<template>
  <div class="layout-header">
    <a-layout-header>
      BI系统
      <a-button type="primary" class="quitLogin" @click="quitLoginClick">退出登录</a-button>
    </a-layout-header>
  </div>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {}
  },
  mounted() {},
  methods: {
    // 退出登陆
    quitLoginClick () {
      sessionStorage.clear() // 退出登陆后清空缓存
      this.$router.push('/login')
    }
  }
};
</script>

<style scoped lang="scss">
.layout-header {
  // position: fixed;
  top: 0;
  left: 400px;
  right: 0;
  height: 64px;
  transition: left .3s;
  .ant-layout-header {
    font-size: 30px;
    background: #fff;
    padding: 0 10px;
  }
  .quitLogin {
    position: absolute;
    top: 15px;
    right: 50px;
  }
}
</style>
