import * as types from './mutation-types'

export const loginAction = ({ commit, state }, { userInfo, isLogin }) => {
  commit(types.SET_USERINFO, userInfo)
  commit(types.SET_LOGIN, isLogin)
}

// export const setUser = ({ commit }, user) => {
//   commit('userStatus', user)
// }
