// 导航菜单结构
import Modules from './modules'

export default [
  { module: Modules.ModuleHome },

  // {
  //   module: Modules.ModuleDemo,
  //   routes: [
  //     {
  //       module: Modules.ModuleDemo1
  //     },
  //     {
  //       module: Modules.ModuleDemoDetail,
  //       routes: [
  //         {
  //           module: Modules.ModuleDemoDetail1
  //         }
  //       ]
  //     }
  //   ]
  // },

  // 表格部分
  // 订单明细
  { module: Modules.ModuleOrderList },

  // 表格部分
  // 注册用户明细
  { module: Modules.ModuleRegisterList },

  // 表格部分
  // 销售业绩统计
  // { module: Modules.ModuleSalesList },

  // 表格部分
  // 销售业绩统计
  { module: Modules.ModuleSalesListNew },
  // 表格部分
  // 费用明细
  { module: Modules.ModuleCostListNew }
]
